.chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.chip-value {
  background-color: #b3d8ff;
  color: #29487f;
  user-select: none;
  flex-wrap: wrap;
  white-space: wrap;
}

.chip-value-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip-value:hover {
  color: #fff;
  background-color: #29487f;
}

.chip-value-xxsmall {
  border-radius: 2px;
  padding: 0px 4px;
  gap: 4px;
  font-size: 11px;
  font-weight: 400;
}

.chip-value-xsmall {
  border-radius: 4px;
  padding: 4px 6px;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
}

.chip-value-small {
  border-radius: 4px;
  padding: 4px 8px;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
}

.chip-value-medium {
  border-radius: 4px;
  padding: 6px 8px;
  gap: 8px;
  font-size: 20px;
  font-weight: 400;
}

.chip-button {
  all: unset;
  margin-left: 0.3rem;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
}

.chip-button-clear-all {
  color: #0628cd;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  user-select: none;
}
